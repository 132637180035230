/*//////////////////////////////////////////////////SPECIFIC*/

* {
  margin: 0;
  padding: 0;
}

*, *:after, *:before {
  box-sizing: border-box;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  line-height: 135%;
}

body, select, .nofont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.t-vertical {
  transform-origin: top left;
  transform: rotateZ(-90deg) translateX(-100%);
}

.p-border {
  padding: .3em .5em;
}

body, a, select, input { color: #353535; }
html, #root, input, .back-white {
  background-color: #fcfcfc;
  /* cursor: crosshair; */
  /* cursor: context-menu; */
  /* cursor: text; */
}

.small { font-size: .8em; }

/*//////////////////////////////////////////////////GENERAL*/

::placeholder {
  color: lightgrey;
}

span {
vertical-align: bottom;
}

p {
  min-height: 1.35em;
}

a {
  text-decoration: none;
}

input, textarea {
  display: inline;
  appearance: none;
  box-shadow: none;
  outline: none;
  outline-offset: 2px;
  border: none;
}
textarea {
  resize: none;
  height: 1em;
}
input {
  vertical-align: middle;
}
input[type="submit"]:hover { background-color: black; color: white; }
input:-webkit-autofill { -webkit-box-shadow: 0 0 0 30px white inset; }

select {
background-color: transparent;
background-image: none;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
box-shadow: none;
border: none;
cursor: pointer;
}

.p-h { padding: .5em; }
.pt-h { padding-top: .5em; }
.pr-h { padding-right: .5em; }
.pb-h { padding-bottom: .5em; }
.pl-h { padding-left: .5em; }
.p-1 { padding: 1em; }
.pt-1 { padding-top: 1em; }
.pr-1 { padding-right: 1em; }
.pb-1 { padding-bottom: 1em; }
.pl-1 { padding-left: 1em; }
.m-h { margin: .5em; }
.mt-h { margin-top: .5em; }
.mr-h { margin-right: .5em; }
.mb-h { margin-bottom: .5em; }
.ml-h { margin-left: .5em; }
.m-1 { margin: 1em; }
.mt-1 { margin-top: 1em; }
.mr-1 { margin-right: 1em; }
.mb-1 { margin-bottom: 1em; }
.ml-1 { margin-left: 1em; }

.width-screen { width: 100vw; }
.width { width: 100%; }
.width-0 { width: 0; }
.width-30 { width: 30%; }
.width-70 { width: 70%; }
.max-width-reading { max-width: 40em; }
.height { height: 100%; }
.height-screen { height: 100vh; }
.min-height { min-height: 100%; }
.min-height-screen { min-height: 100vh; }
@media screen and (max-width: 32rem) {
  .width-sm { width: 100%; }
}

.flex { display: flex; }
.flexinline { display: inline-flex; }
.flex-row { flex-direction: row; }
.flex-column { flex-direction: column; }
.flex-align-x-left { justify-content: flex-start; }
.flex-align-x-center { justify-content: center; }
.flex-align-y-top { align-items: flex-start; }
.flex-align-y-center { align-items: center; }
.flex-align-y-bottom { align-items: flex-end; }
.flex-wrap { flex-wrap: wrap; }
.flex-a { flex-basis: 0; }
.flex-0 { flex-basis: 0%; }
.flex-15 { flex-basis: 15%; }
.flex-20 { flex-basis: 20%; }
.flex-25 { flex-basis: 25%; }
.flex-30 { flex-basis: 30%; }
.flex-35 { flex-basis: 35%; }
.flex-40 { flex-basis: 40%; }
.flex-45 { flex-basis: 45%; }
.flex-50 { flex-basis: 50%; }
.flex-55 { flex-basis: 55%; }
.flex-60 { flex-basis: 60%; }
.flex-65 { flex-basis: 65%; }
.flex-70 { flex-basis: 70%; }
.flex-75 { flex-basis: 75%; }
.flex-80 { flex-basis: 80%; }
.flex-85 { flex-basis: 85%; }
.flex-90 { flex-basis: 90%; }
.flex-95 { flex-basis: 95%; }
.flex-100 { flex-basis: 100%; }
.flex-noshrink { flex-shrink: 0; }
.flex-grow { flex-grow: 1; }
@media screen and (max-width: 32rem) {
  .flex-sm { flex-basis: 100%; }
}

.center-auto {
  margin-left: auto;
  margin-right: auto;
}

.relative { position: relative; }
.absolute { position: absolute; }
.fixed { position: fixed; }
.top { top: 0; }
.right { right: 0; }
.bottom { bottom: 0; }
.left { left: 0; }

.block { display: block; }
.inline { display: inline; }
.inline-block { display: inline-block; }
.none { display: none; }

.hidden { overflow: hidden; }
.auto { overflow: auto; }

.pointer { cursor: pointer; }
.noselect { user-select: none; }

.t-center { text-align: center; }
.t-nowrap { white-space: nowrap; }
.t-ellipsis { text-overflow: ellipsis; }
.t-shadow { text-shadow: 2px 2px 4px black; }
.t-underline { text-decoration: underline; }
.t-italic { font-style: italic; }

.lightgrey { color: rgba(0,0,0,0.2); }

.bor {
  border: 2px solid rgba(0,0,0,0.2);
  border-collapse: collapse;
}
.bor-top { border-top: solid rgba(0,0,0,0.2) 2px; }
.bor-left { border-left: solid rgba(0,0,0,0.2) 2px; }
.bor-bottom { border-bottom: solid rgba(0,0,0,0.2) 2px; }
.radius { border-radius: 1em; }

.shadow { box-shadow: .5em .5em 0 rgba(0,0,0,0.1); }

.above { z-index: 10; }
